<template>

  <!-- Movie -->
  <template v-if="type === 'movie'">
    <figure :class="design" v-if="item.poster_path && !item.adult" @click="viewItem('Movie', item.id, item.title)">
      <picture :class="{ watched: watched.movies.includes(item.id)}">
        <img :src="`https://image.tmdb.org/t/p/w300/${item.poster_path}`" :alt="`Cover art for ${item.title}`">
      </picture>
      <figcaption>
        <span
          v-if="watched.movies.includes(item.id)"
          class="action-icon is-active"
          @click.stop="updateWatchedLists({ operation: 'remove', type: 'Movies', item })">
          <img src="@/assets/icons/watched.svg" alt="">
        </span>
        <span
          v-else
          class="action-icon not-active"
          @click.stop="updateWatchedLists({ operation: 'add', type: 'Movies', item })">
          <img src="@/assets/icons/not-watched.svg" alt="">
        </span>
      </figcaption>
    </figure>
  </template>

  <!-- Show -->
  <template v-else-if="type === 'show'">
    <figure :class="design" v-if="item.poster_path && !item.adult" @click="viewItem('Show', item.id, item.name)">
      <picture :class="{ watched: watched.shows.includes(item.id)}">
        <img :src="`https://image.tmdb.org/t/p/w300/${item.poster_path}`" :alt="`Cover art for ${item.name}`">
      </picture>
      <figcaption>
        <span
          v-if="watched.shows.includes(item.id)"
          class="action-icon is-active"
          @click.stop="updateWatchedLists({ operation: 'remove', type: 'Shows', item })">
          <img src="@/assets/icons/watched.svg" alt="">
        </span>
        <span
          v-else
          class="action-icon not-active"
          @click.stop="updateWatchedLists({ operation: 'add', type: 'Shows', item })">
          <img src="@/assets/icons/not-watched.svg" alt="">
        </span>
      </figcaption>
    </figure>
  </template>

  <!-- Person -->
  <template v-else-if="type === 'person'">
    <figure :class="design" v-if="item.profile_path" @click="viewItem('Star', item.id, item.name)">
      <picture class="has-overlay">
        <img :src="`https://image.tmdb.org/t/p/w300/${item.profile_path}`" :alt="`Profile image for ${item.name}`">
      </picture>
      <figcaption>{{ item.name }}</figcaption>
    </figure>
  </template>

</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    props: {
      item: { type: Object, required: true },
      type: { type: String, required: true },
      design: { type: String, required: true }
    },
    computed: {
      ...mapState('user', ['watched'])
    },
    methods: {
      ...mapActions('user', ['updateWatchedLists']),

      viewItem(route, id, item) {
        this.$router.push({ name: route, query: { id, name: item }})
      }
    }
  }
</script>
